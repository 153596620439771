import { useState, useCallback } from "react";
import fetcher from "../../utils/fetcher";

const useAPI = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async (status = false, url, method = "GET", body = {}) => {
    setLoading(true);
    try {

      const response = await fetcher({
        method,
        url,
        body
      });

      setData({ ...response.data, status: response.status });
      setStatus(response.status);
      setError(false);
      if (status)
        return {
          ...response.data,
          status: response.status,
          headers: response.headers,
        };
      if (!status) return response.data;
    } catch (error) {
      setError(true);
      throw error; // Re-throwing the error for better error handling in components
    } finally {
      setLoading(false);
    }
  }, []);

  const getWithStatus = useCallback(async (url, callback) => {
    try {
      const result = await fetchData(true, url, "GET");
      if (callback) callback();
      return result;
    } catch (error) {
      // Handle error if needed
    }
  }, [fetchData]);

  const get =  useCallback(async (url, callback) => {
    try {
      const result = await fetchData(false, url, "GET");
      if (callback) callback();
      return result;
    } catch (error) {
      // Handle error if needed
    }
  }, [fetchData])

  const post = useCallback(async (url, body, callback) => {
    try {
      const result = await fetchData(false, url, "POST", body);
      if (callback) callback();
      return result;
    } catch (error) {
      // Handle error if needed
    }
  }, [fetchData]);

  const clearErrorMessage = () => {
    setError(false);
  };

  return {
    data,
    error,
    loading,
    get,
    getWithStatus,
    post,
    clearErrorMessage,
    status,
  };
};

export default useAPI;
