import spinner from "../assets/img/spinner-third.svg";

const SPLASH_SCREEN_DISPLAY_TYPE = {
  logo: "logo",
  spinner: "spinner",
  none: "none",
};

const SplashScreen = (
  { displayType = SPLASH_SCREEN_DISPLAY_TYPE.none } = {
    displayType: SPLASH_SCREEN_DISPLAY_TYPE.none,
  }
) => (
  <div className="flex justify-center align-center items-center h-screen h-screen">
    {[
      SPLASH_SCREEN_DISPLAY_TYPE.logo,
      SPLASH_SCREEN_DISPLAY_TYPE.spinner,
    ].includes(displayType) && (
      <img
        src={displayType === SPLASH_SCREEN_DISPLAY_TYPE.logo ? "/landvoice-logo-300.png" : spinner}
        alt=""
      />
    )}
  </div>
);

export default SplashScreen;
