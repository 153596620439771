import axios from "axios";
import Cookies from "universal-cookie";

const fetcher = async ({ url, method = "GET", body = {} }) => {
  const response = await axios({
    method,
    url,
    data: body,
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": new Cookies().get("csrftoken"),
    },
  });

  const isImpersonating =
    response.headers["x-impersonate"] ||
    response.headers["x-impersonate"]?.toLowerCase() === "true" ||
    response.headers["x-impersonate"] === true
      ? true
      : false;

  if (isImpersonating) {
    sessionStorage.setItem("loginAs", true);
  } else {
    sessionStorage.setItem("loginAs", false);
  }

  return response;
};

export default fetcher;
