// ErrorBoundary.js

import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    // Log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render a fallback UI here
      return <p>Something went wrong. Please try again later.</p>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
